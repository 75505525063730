import { create } from '@storybook/theming/create';
import logo from './logo.svg'

export default create({
  base: 'dark',

  brandTitle: 'Unc Inc Kitchensink',
  brandUrl: '/',
  brandImage: logo,
});
